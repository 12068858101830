import React from 'react';
import { useRedirect } from '~/features/redirect/use-redirect';
import { useLoginUser } from '~/common/providers/auth/use-login-user';
import { useTenantSettings } from '~/common/hooks/use-tenant-settings';
import { useEnabledFeatureMap } from '~/common/hooks/use-enabled-feature-map';

const Redirect = React.memo(function Redirect() {
  const loginUser = useLoginUser();
  const { data: settings } = useTenantSettings();

  const role = loginUser?.userInfo.role;
  const firstViewPage = settings?.firstViewPage;
  const { data: enabledFeatureMap } = useEnabledFeatureMap();
  const isFinancialSheetHistoryAndDepartmentEnabled =
    enabledFeatureMap?.isFinancialSheetHistoryAndDepartmentEnabled;
  useRedirect(role, firstViewPage, isFinancialSheetHistoryAndDepartmentEnabled);
  return <></>;
});

export { Redirect };
