import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { SettingsViewFirstViewPageEnum } from '~/common/api';
import { assertNever } from '~/common/utils/assertNever';
import { Role } from '~/common/providers/auth/role';

const useRedirect = (
  role: Role | undefined,
  firstViewPage: SettingsViewFirstViewPageEnum | undefined,
  isFinancialSheetHistoryAndDepartmentEnabled: boolean | undefined,
) => {
  const { push } = useRouter();
  const redirectByRoleAndSettings = useCallback(async () => {
    if (!role || !firstViewPage || isFinancialSheetHistoryAndDepartmentEnabled === undefined)
      return;
    if (role === 'IT_STAFF') {
      await push('/settings/tenant');
      return;
    }
    if (!isFinancialSheetHistoryAndDepartmentEnabled) {
      // 推移表、部署別表、部署別対比表が無効の場合は、強制的にレポート画面に遷移する
      await push('/financial-sheets/reports');
      return;
    }
    switch (firstViewPage) {
      case SettingsViewFirstViewPageEnum.FinancialSheetHistory: {
        await push('/financial-sheets/history');
        break;
      }
      case SettingsViewFirstViewPageEnum.Reports: {
        await push('/financial-sheets/reports');
        break;
      }
      default: {
        assertNever(firstViewPage);
      }
    }
  }, [push, role, firstViewPage, isFinancialSheetHistoryAndDepartmentEnabled]);

  useEffect(() => {
    redirectByRoleAndSettings();
  }, [redirectByRoleAndSettings, role, firstViewPage, isFinancialSheetHistoryAndDepartmentEnabled]);
};

export { useRedirect };
