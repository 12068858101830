import { SWRResponse } from 'swr';
import { SettingControllerApi, SettingsView } from '~/common/api';
import { useFetch } from '~/common/hooks/use-fetch';

const useTenantSettings = (): SWRResponse<SettingsView | undefined, Error> => {
  return useFetch('/api/settings', (reqOps) =>
    new SettingControllerApi().get(reqOps).then((response) => response.data),
  );
};

export { useTenantSettings };
